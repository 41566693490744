.theme {
  --bg: #f9f9f9;
  --bg-light: #fff;
  --hr: #f0f0f0;
  --hr-dark: transparent;
  --sidenav: #fff;
  --sub-menu-item: #f9f9f9;
  --menu-item-active: #f0f0f0;
  --text: #030303;
  --text-invert: #e6e6e6;
  --toggle: #e6e6e6;
  --textarea-header: #e6e6e6;
  --theme-mode-icon: #fff;
  --box-shadow: 0 2px 2px 0 rgba(60, 75, 100, 0.14),
    0 3px 1px -2px rgba(60, 75, 100, 0.12), 0 1px 5px 0 rgba(60, 75, 100, 0.2);
  --hover-box-shadow: 0 7px 16px rgba(0, 0, 0, 0.14),
    0 4px 4px rgba(0, 0, 0, 0.16);
  --lightbox-bg: rgba(255, 255, 255, 0.98);
  --lightbox-dot: #c0c0c0;
}

[data-theme="dark"] {
  --bg: #030303;
  --bg-light: #030303;
  --hr: #454545;
  --hr-dark: #454545;
  --sidenav: #1a1a1b;
  --sub-menu-item: #2f2f2f;
  --menu-item-active: #2a2a2a;
  --text: #e6e6e6;
  --text-invert: #030303;
  --toggle: #fff;
  --textarea-header: #363636;
  --theme-mode-icon: #ff7a00;
  --box-shadow: none;
  --hover-box-shadow: none;
  --lightbox-bg: rgba(0, 0, 0, 0.98);
  --lightbox-dot: #f0f0f0;
}
